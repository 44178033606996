import { startTransition, useEffect } from 'react';

import { useAppDispatch } from '../../store/useAppDispatch';
import { globalProgressFeedbackSliceActions } from './store/slice';

export const DisplayGlobalProgressFeedback = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    startTransition(() => {
      dispatch(globalProgressFeedbackSliceActions.display());
    });
    return () => {
      startTransition(() => {
        dispatch(globalProgressFeedbackSliceActions.hide());
      });
    };
  }, [dispatch]);

  return null;
};
