import type { AxiosRequestConfig } from 'axios';

import { CommentTemplates } from '@features/comment-templates/services';
import type { StrapiAuthentication, StrapiUser } from '@helpers/models/strapi';
import { request, RequestPayload } from '@helpers/requests';

import type { Evaluations } from '../evaluations/services';

export interface AuthenticationByCredentialsPayload extends RequestPayload {
  data: {
    identifier: string;
    password: string;
  };
  extra: {
    shouldMemorizeIdentifier: boolean;
  };
}

export type AuthenticationByCredentialsDataReturned = StrapiAuthentication;

const authenticationByCredentialsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/auth/local',
  method: 'POST',
};

export const authenticationByCredentialsRequest = request.create.withoutAuthLogic<
  AuthenticationByCredentialsDataReturned,
  AuthenticationByCredentialsPayload
>(authenticationByCredentialsConfig);

export type GetMePayload = void;

export type User = StrapiUser & {
  isCorrector: boolean;
  comment_templates?: CommentTemplates[];
  evaluations?: Evaluations[];
};

export type GetMeDataReturned = User;

const getMeConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/users/me',
  method: 'GET',
};

export const getMeRequest = request.create.withAuthLogic<
  GetMeDataReturned,
  GetMePayload
>(getMeConfig);

export type ForgotPasswordPayload = { data: { email: string } };

export type ForgotPasswordDataReturned = undefined;

const forgotPasswordConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/auth/forgot-password',
  method: 'POST',
};

export const forgotPasswordRequest = request.create.withoutAuthLogic<
  ForgotPasswordDataReturned,
  ForgotPasswordPayload
>(forgotPasswordConfig);

export type ResetPasswordPayload = {
  data: {
    code: string;
    password: string;
    passwordConfirmation: string;
  };
};

export type ResetPasswordDataReturned = AuthenticationByCredentialsDataReturned;

const resetPasswordConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/auth/reset-password',
  method: 'POST',
};

export const resetPasswordRequest = request.create.withoutAuthLogic<
  ResetPasswordDataReturned,
  ResetPasswordPayload
>(resetPasswordConfig);
