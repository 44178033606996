import type { AxiosRequestConfig } from 'axios';

import { request, RequestPayload } from '@helpers/requests';

import type { Test } from './model';

export type CreateTestReturned = Test;

export interface CreateTestPayload extends RequestPayload {
  data: FormData;
}

const createTestConfig: AxiosRequestConfig = {
  baseURL: process.env.SERVER_BASE_URL,
  url: '/tests',
  method: 'POST',
};

export const createTestRequest = request.create.withAuthLogic<
  CreateTestReturned,
  CreateTestPayload
>(createTestConfig);
