import type { AxiosInstance } from 'axios';

import type { AnyRequestInterceptor, AnyResponseInterceptor } from './models';

/**
 * Add a request interceptor to an axios instance
 * allows you to modify a query configuration
 * @param instance
 * @param interceptors
 */
export const addRequestInterceptors = <PayloadRequestInterceptor = void>(
  instance: AxiosInstance,
  interceptors: [
    AnyRequestInterceptor<PayloadRequestInterceptor>,
    PayloadRequestInterceptor
  ][]
) => {
  interceptors.forEach(([interceptor, payload]) => {
    instance.interceptors.request.use(config => interceptor(config, payload));
  });
};

/**
 * Add a response interceptor to an axios instance
 * allows you to transform a query response
 * @param instance
 * @param interceptors
 */
export const addResponseInterceptors = <
  Data = unknown,
  PayloadResponseInterceptor = void
>(
  instance: AxiosInstance,
  interceptors: [
    AnyResponseInterceptor<Data, PayloadResponseInterceptor>,
    PayloadResponseInterceptor
  ][]
) => {
  interceptors.forEach(([interceptor, payload]) => {
    instance.interceptors.response.use(config => interceptor(config, payload));
  });
};
