import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import { getSchoolsCountRequest } from '../../services';
import type { GetSchoolsCountReturned } from '../../services';

export type GetCountSchoolsThunk = GetSchoolsCountReturned;

export const getCountSchoolsThunk = createAppAsyncThunk<GetSchoolsCountReturned>(
  'school/count',
  async (payload, api) => {
    const response = await request.executeWithAuthLogic(api, getSchoolsCountRequest);

    return response.data;
  }
);
