import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetStudentsPayload,
  getStudentsRequest,
  GetStudentsReturned,
} from '../../services';

export type GetStudentsThunkReturned = GetStudentsReturned;
export type GetStudentsThunkPayload = GetStudentsPayload;
export const getStudentsThunk = createAppAsyncThunk<
  GetStudentsThunkReturned,
  GetStudentsThunkPayload
>('students/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getStudentsRequest,
    payload
  );

  return response.data;
});
