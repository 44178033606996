import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetCommentTemplatesPayload,
  getCommentTemplatesRequest,
  GetCommentTemplatesReturned,
} from '../../services';

export type GetCommentTemplatesThunkReturned = GetCommentTemplatesReturned;
export type GetCommentTemplatesThunkPayload = GetCommentTemplatesPayload;
export const getCommentTemplatesThunk = createAppAsyncThunk<
  GetCommentTemplatesThunkReturned,
  GetCommentTemplatesThunkPayload | undefined
>('comment-templates/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getCommentTemplatesRequest,
    payload
  );

  return response.data;
});
