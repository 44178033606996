import type { Reducer } from '@reduxjs/toolkit';
import type { Action } from 'redux';

import type { StorageKeys } from '../storage-manager/keys';
import { localStorageManager } from '../storage-manager/localStorageManager';

/**
 * Enables persist a reducer state via local storage
 * persistence is only used to hydrate the initial state of the reducer and not to keep it synchronized
 * @param reducer
 * @param localStorageKey
 */
export const reducerWithLocalStoragePersistance =
  <State extends Record<string, any>>(
    reducer: Reducer<State>,
    localStorageKey: StorageKeys
  ) =>
  (state: State | undefined, action: Action): State => {
    const next = reducer(state, action);

    if (state && next !== state) {
      // In other case we wants to store value in every cases

      localStorageManager.set<State>(localStorageKey, next);
      return next;
    }
    // App just mount, hydrate reducer
    if (!state) {
      const dataPersisted = localStorageManager.get<null | State>(localStorageKey);

      if (dataPersisted) {
        return dataPersisted;
      }
    }

    return state ?? next;
  };
