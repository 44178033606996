import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  getPassationsCountRequest,
  GetPassationsCountReturned,
} from '../../services';

export type GetCountPassationsThunk = GetPassationsCountReturned;

export const getCountPassationsThunk =
  createAppAsyncThunk<GetPassationsCountReturned>(
    'passations/count',
    async (payload, api) => {
      const response = await request.executeWithAuthLogic(
        api,
        getPassationsCountRequest
      );

      return response.data;
    }
  );
