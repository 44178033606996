import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  CreateCommentTemplatesPayload,
  createCommentTemplatesRequest,
  CreateCommentTemplatesReturned,
} from '../../services';

type CreateCommentTemplatesThunkPayload = CreateCommentTemplatesPayload;
type CreateCommentTemplatesThunkReturned = CreateCommentTemplatesReturned;

export const createCommentTemplatesThunk = createAppAsyncThunk<
  CreateCommentTemplatesThunkReturned,
  CreateCommentTemplatesThunkPayload
>('comment-templates/create', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    createCommentTemplatesRequest,
    payload
  );

  return response.data;
});
