import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  EditCommentTemplatesPayload,
  editCommentTemplatesRequest,
  EditCommentTemplatesReturned,
} from '../../services';

type EditCommentTemplatesThunkPayload = EditCommentTemplatesPayload;
type EditCommentTemplatesThunkReturned = EditCommentTemplatesReturned;

export const editCommentTemplatesThunk = createAppAsyncThunk<
  EditCommentTemplatesThunkReturned,
  EditCommentTemplatesThunkPayload
>('comment-templates/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    editCommentTemplatesRequest,
    payload
  );

  return response.data;
});
