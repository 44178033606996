import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  EditPassationPayload,
  editPassationsRequest,
  EditPassationReturned,
} from '../../services';

type EditPassationThunkPayload = EditPassationPayload;
type EditPassationThunkReturned = EditPassationReturned;

export const editPassationThunk = createAppAsyncThunk<
  EditPassationThunkReturned,
  EditPassationThunkPayload
>('passation/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    editPassationsRequest,
    payload
  );

  return response.data;
});
