import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import { getStudentsCountRequest, GetStudentsCountReturned } from '../../services';

export type GetCountStudentsThunk = GetStudentsCountReturned;

export const getCountStudentsThunk = createAppAsyncThunk<GetStudentsCountReturned>(
  'students/count',
  async (payload, api) => {
    const response = await request.executeWithAuthLogic(
      api,
      getStudentsCountRequest
    );

    return response.data;
  }
);
