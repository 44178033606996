import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  DeletePassationPayload,
  deletePassationRequest,
  DeletePassationReturned,
} from '../../services';

type DeletePassationThunkPayload = DeletePassationPayload;
type DeletePassationThunkReturned = DeletePassationReturned;

export const deletePassationThunk = createAppAsyncThunk<
  DeletePassationThunkReturned,
  DeletePassationThunkPayload
>('passation/delete', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    deletePassationRequest,
    payload
  );

  return response.data;
});
