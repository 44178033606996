import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetStudentPayload,
  getStudentRequest,
  GetStudentReturned,
} from '../../services';

export type GetStudentThunkReturned = GetStudentReturned;
export type GetStudentThunkPayload = GetStudentPayload;

export const getStudentThunk = createAppAsyncThunk<
  GetStudentThunkReturned,
  GetStudentThunkPayload
>('student/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getStudentRequest,
    payload
  );

  return response.data;
});
