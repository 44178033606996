import delay from 'delay';

import {
  authenticationByCredentialsRequest,
  ForgotPasswordPayload,
  forgotPasswordRequest,
  GetMeDataReturned,
  GetMePayload,
  getMeRequest,
} from '@features/authentication';
import { request } from '@helpers/requests';
import { clearStorage } from '@helpers/storage-manager';

import { createAppAsyncThunk } from '../../../store/createAppAsyncThunk';
import {
  AuthenticationByCredentialsDataReturned,
  AuthenticationByCredentialsPayload,
  ResetPasswordDataReturned,
  ResetPasswordPayload,
  resetPasswordRequest,
} from '../services';

interface AuthenticationByCredentialsThunkReturned {
  data: AuthenticationByCredentialsDataReturned;
  shouldMemorizeIdentifier: boolean;
}

type AuthenticationByCredentialsThunkPayload = AuthenticationByCredentialsPayload;

export const authenticationByCredentialsThunk = createAppAsyncThunk<
  AuthenticationByCredentialsThunkReturned,
  AuthenticationByCredentialsThunkPayload
>('authentication-by-credentials', async payload => {
  const response = await authenticationByCredentialsRequest(payload);

  return {
    shouldMemorizeIdentifier: payload.extra.shouldMemorizeIdentifier,
    data: response.data,
  };
});

export const getMeThunk = createAppAsyncThunk<GetMeDataReturned, GetMePayload>(
  'get-me',
  async (payload, api) => {
    const response = await request.executeWithAuthLogic(api, getMeRequest, payload);

    return response.data;
  }
);

export const logoutThunk = createAppAsyncThunk('logout', async () => {
  clearStorage();
  await delay(1000);
});

export const forgotPasswordThunk = createAppAsyncThunk<
  { success: true },
  ForgotPasswordPayload
>('password/forgot', async payload => {
  await forgotPasswordRequest(payload);

  return { success: true };
});

export const resetPasswordThunk = createAppAsyncThunk<
  ResetPasswordDataReturned,
  ResetPasswordPayload
>('password/reset', async payload => {
  const response = await resetPasswordRequest(payload);

  return response.data;
});
