import stringTemplate from 'string-template';

import type { AnyRequestInterceptor } from './models';

/**
 * Replace {template} schema in path by template value
 * ex: api/users/{me}, {me: 'toto'} => api/users/toto
 * @param config
 * @param templateValues
 */
export const pathTemplatingInterceptor: AnyRequestInterceptor<
  Record<string, string>
> = (config, templateValues) => ({
  ...config,
  url: stringTemplate(config.url ?? '', templateValues),
});
