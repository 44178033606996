import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { logoutThunk } from '@features/authentication/redux/thunks';
import { editPassationThunk } from '@features/passations/redux/thunks/editPassation.thunk';
import { getPassationThunk } from '@features/passations/redux/thunks/getPassation.thunk';
import type { Passation, StudentInPassation } from '@features/passations/services';
import { createTestsThunk } from '@features/tests/redux/thunks';

import { createPassationsThunk } from './thunks/createPassations.thunk';
import { deletePassationThunk } from './thunks/deletePassation.thunk';
import { getCountPassationsThunk } from './thunks/getCountPassations.thunk';
import { getPassationsThunk } from './thunks/getPassations.thunk';
import { getStudentsInPassationThunk } from './thunks/getStudentsInPassation.thunk';

interface PassationsState {
  collection: EntityState<Passation>;
  studentsInPassations: EntityState<StudentInPassation>;
  count: number;
}

export const passationsEntityAdapter = createEntityAdapter<Passation>();

export const studentsInPassationsEntityAdapter =
  createEntityAdapter<StudentInPassation>();

const initialState: PassationsState = {
  collection: passationsEntityAdapter.getInitialState(),
  studentsInPassations: studentsInPassationsEntityAdapter.getInitialState(),
  count: 0,
};

export const passationsSlice = createSlice({
  name: 'passations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createTestsThunk.fulfilled, (state, action) => {
      if (!action.payload.student?.id) {
        return;
      }
      state.studentsInPassations = studentsInPassationsEntityAdapter.updateOne(
        state.studentsInPassations,
        {
          id: action.payload.student.id,
          changes: {
            tests: [action.payload],
          },
        }
      );
    });

    builder.addCase(createPassationsThunk.fulfilled, (state, action) => {
      state.collection = passationsEntityAdapter.addOne(
        state.collection,
        action.payload
      );
      state.count += 1;
    });

    builder.addCase(editPassationThunk.fulfilled, (state, action) => {
      state.collection = passationsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(getPassationsThunk.fulfilled, (state, action) => {
      state.collection = passationsEntityAdapter.setAll(
        state.collection,
        action.payload
      );
    });

    builder.addCase(getCountPassationsThunk.fulfilled, (state, action) => {
      state.count = action.payload;
    });

    builder.addCase(deletePassationThunk.fulfilled, (state, action) => {
      state.collection = passationsEntityAdapter.removeOne(
        state.collection,
        action.payload.id
      );
      state.count -= 1;
    });

    builder.addCase(getStudentsInPassationThunk.fulfilled, (state, action) => {
      state.studentsInPassations = studentsInPassationsEntityAdapter.setAll(
        state.studentsInPassations,
        action.payload
      );
    });

    builder.addCase(getPassationThunk.fulfilled, (state, action) => {
      state.collection = passationsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
