import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { logoutThunk } from '@features/authentication/redux/thunks';
import { editStudentThunk } from '@features/students/redux/thunks/editStudent.thunk';
import { getStudentThunk } from '@features/students/redux/thunks/getStudent.thunk';

import type { Student } from '../services';
import { createStudentThunk } from './thunks/createStudent.thunk';
import { deleteStudentThunk } from './thunks/deleteStudent.thunk';
import { getCountStudentsThunk } from './thunks/getCountStudents.thunk';
import { getStudentsThunk } from './thunks/getStudents.thunk';

interface StudentsState {
  collection: EntityState<Student>;
  count: number;
}

export const studentsEntityAdapter = createEntityAdapter<Student>();

const initialState: StudentsState = {
  collection: studentsEntityAdapter.getInitialState(),
  count: 0,
};

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createStudentThunk.fulfilled, (state, action) => {
      state.collection = studentsEntityAdapter.addOne(
        state.collection,
        action.payload
      );
      state.count += 1;
    });

    builder.addCase(deleteStudentThunk.fulfilled, (state, action) => {
      state.collection = studentsEntityAdapter.removeOne(
        state.collection,
        action.payload.id
      );
      state.count -= 1;
    });

    builder.addCase(getStudentsThunk.fulfilled, (state, action) => {
      state.collection = studentsEntityAdapter.setAll(
        state.collection,
        action.payload
      );
    });

    builder.addCase(getStudentThunk.fulfilled, (state, action) => {
      state.collection = studentsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(editStudentThunk.fulfilled, (state, action) => {
      state.collection = studentsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(getCountStudentsThunk.fulfilled, (state, action) => {
      state.count = action.payload;
    });

    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
