import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { logoutThunk } from '@features/authentication/redux/thunks';

import type { Test } from '../services/model';
import { createTestsThunk, editTestThunk } from './thunks';

interface TestsState {
  collection: EntityState<Test>;
}

const testsEntityAdapter = createEntityAdapter<Test>();

const initialState: TestsState = {
  collection: testsEntityAdapter.getInitialState(),
};

export const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createTestsThunk.fulfilled, (state, action) => {
      state.collection = testsEntityAdapter.addOne(state.collection, action.payload);
    });
    builder.addCase(editTestThunk.fulfilled, (state, action) => {
      state.collection = testsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });
    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
