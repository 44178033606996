import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import { request } from '@helpers/requests';

import type { Test } from './model';

const deleteTestConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/tests/{id}',
  method: 'DELETE',
};

export interface DeleteTestPayload {
  templateValues: { id: EntityId };
}

export type DeleteTestReturned = Test;

export const deleteTestRequest = request.create.withAuthLogic<
  DeleteTestReturned,
  DeleteTestPayload
>(deleteTestConfig);
