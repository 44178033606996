import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { logoutThunk } from '@features/authentication/redux/thunks';

import type { CommentTemplates } from '../services';
import { createCommentTemplatesThunk } from './thunks/createCommentTemplates.thunk';
import { deleteCommentTemplatesThunk } from './thunks/deleteCommentTemplates.thunk';
import { editCommentTemplatesThunk } from './thunks/editCommentTemplates.thunk';
import { getCommentTemplatesThunk } from './thunks/getCommentTemplates.thunk';

interface CommentTemplatesState {
  collection: EntityState<CommentTemplates>;
}

export const commentTemplatesEntityAdapter = createEntityAdapter<CommentTemplates>();

const initialState: CommentTemplatesState = {
  collection: commentTemplatesEntityAdapter.getInitialState(),
};

export const commentTemplatesSlice = createSlice({
  name: 'comment-templates',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createCommentTemplatesThunk.fulfilled, (state, action) => {
      state.collection = commentTemplatesEntityAdapter.addOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(deleteCommentTemplatesThunk.fulfilled, (state, action) => {
      state.collection = commentTemplatesEntityAdapter.removeOne(
        state.collection,
        action.payload.id
      );
    });

    builder.addCase(getCommentTemplatesThunk.fulfilled, (state, action) => {
      state.collection = commentTemplatesEntityAdapter.setAll(
        state.collection,
        action.payload
      );
    });

    builder.addCase(editCommentTemplatesThunk.fulfilled, (state, action) => {
      state.collection = commentTemplatesEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
