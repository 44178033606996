import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  CreatePassationPayload,
  createPassationRequest,
  CreatePassationReturned,
} from '../../services';

type CreatePassationsThunkPayload = CreatePassationPayload;
type CreatePassationsThunkReturned = CreatePassationReturned;

export const createPassationsThunk = createAppAsyncThunk<
  CreatePassationsThunkReturned,
  CreatePassationsThunkPayload
>('passations/create', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    createPassationRequest,
    payload
  );

  return response.data;
});
