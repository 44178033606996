import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetSchoolsPayload,
  getSchoolsRequest,
  GetSchoolsReturned,
} from '../../services';

export type GetSchoolsThunkReturned = GetSchoolsReturned;
export type GetSchoolsThunkPayload = GetSchoolsPayload;
export const getSchoolsThunk = createAppAsyncThunk<
  GetSchoolsThunkReturned,
  GetSchoolsThunkPayload
>('schools/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getSchoolsRequest,
    payload
  );

  return response.data;
});
