import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import { request, RequestPayload } from '@helpers/requests';

export type CommentTemplates = {
  id: string;
  title: string;
  template: string;
};

export type CreateCommentTemplatesReturned = CommentTemplates;

export interface CreateCommentTemplatesPayload extends RequestPayload {
  data: Omit<CommentTemplates, 'id'>;
}

const createCommentTemplatesConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/comment-templates',
  method: 'POST',
};

export const createCommentTemplatesRequest = request.create.withAuthLogic<
  CreateCommentTemplatesReturned,
  CreateCommentTemplatesPayload
>(createCommentTemplatesConfig);

export type EditCommentTemplatesReturned = CommentTemplates;

export interface EditCommentTemplatesPayload extends RequestPayload {
  data: Omit<CommentTemplates, 'id'>;
  templateValues: {
    id: EntityId;
  };
}

const editCommentTemplatesConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/comment-templates/{id}',
  method: 'PUT',
};

export const editCommentTemplatesRequest = request.create.withAuthLogic<
  EditCommentTemplatesReturned,
  EditCommentTemplatesPayload
>(editCommentTemplatesConfig);

export interface GetCommentTemplatesPayload {
  params: {
    _limit: number;
    _start: number;
  };
}

export type GetCommentTemplatesReturned = CommentTemplates[];

const getCommentTemplatesConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/comment-templates',
  method: 'GET',
};

export const getCommentTemplatesRequest = request.create.withAuthLogic<
  GetCommentTemplatesReturned,
  GetCommentTemplatesPayload
>(getCommentTemplatesConfig);

const deleteCommentTemplatesConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/comment-templates/{id}',
  method: 'DELETE',
};

export interface DeleteCommentTemplatesPayload {
  templateValues: { id: EntityId };
}

export type DeleteCommentTemplatesReturned = CommentTemplates;

export const deleteCommentTemplatesRequest = request.create.withAuthLogic<
  DeleteCommentTemplatesReturned,
  DeleteCommentTemplatesPayload
>(deleteCommentTemplatesConfig);
