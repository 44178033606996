import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  CreateStudentPayload,
  createStudentRequest,
  CreateStudentReturned,
} from '../../services';

type CreateStudentThunkPayload = CreateStudentPayload;
type CreateStudentThunkReturned = CreateStudentReturned;

export const createStudentThunk = createAppAsyncThunk<
  CreateStudentThunkReturned,
  CreateStudentThunkPayload
>('student/create', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    createStudentRequest,
    payload
  );

  return response.data;
});
