import { unwrapResult } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import type { DefaultGetThunkApi } from '@helpers/models/async-data';

import { addAuthHeaderInterceptor } from './axios-interceptors/addAuthHeaders.interceptor';
import {
  addRequestInterceptors,
  addResponseInterceptors,
} from './axios-interceptors/addInterceptors';
import type { CreateRequestOptions, RefreshJwt } from './axios-interceptors/models';
import { pathTemplatingInterceptor } from './axios-interceptors/pathTemplating.interceptor';

type DefaultState = { authentication: { token: string | null } };

export interface RequestPayload<
  Data extends Record<string, unknown> = {},
  Params extends Record<string, unknown> = {},
  Extra extends Record<string, unknown> | undefined = {}
> {
  /** Body for request */
  data?: Data;
  /** Url parameters to send with request */
  params?: Params;
  /** any payload for request */
  extra?: Extra;
  /** key/values to replace template in url path ex: api/${id} replaced with {id: 9} */
  templateValues?: Record<string, unknown>;
}

/**
 * Create a request with config and options
 * Use Axios under the hood
 * @param config
 * @param options
 */
const create = <
  /** Data Returned after request fulfilled */
  Data = unknown,
  /** Payload for request axios-interceptors */
  PayloadRequestInterceptors extends void | unknown = void,
  /** Payload for response axios-interceptors */
  PayloadResponseInterceptors extends void | unknown = void
>(
  config: AxiosRequestConfig,
  options?: CreateRequestOptions<
    Data,
    PayloadRequestInterceptors,
    PayloadResponseInterceptors
  >
): Promise<AxiosResponse<Data>> => {
  const instance = axios.create();

  if (!options) {
    return instance.request(config);
  }

  if (options.refreshJwt) {
    createAuthRefreshInterceptor(instance, options.refreshJwt.logic, {
      pauseInstanceWhileRefreshing: true,
      ...options.refreshJwt.options,
    });
  }

  if (options.requestInterceptors) {
    addRequestInterceptors(instance, options.requestInterceptors);
  }

  if (options.responseInterceptors) {
    addResponseInterceptors(instance, options.responseInterceptors);
  }

  return instance.request(config);
};

/**
 * Create Axios Request
 * Add a request interceptor to manage Header authorization
 * Add a response interceptor to manage Jwt refresh logic
 * @param config
 * @param options
 * @returns
 */
const createWithAuthLogic =
  <DataReturned, Payload extends RequestPayload | void>(
    config: AxiosRequestConfig,
    options?: CreateRequestOptions<DataReturned>
  ) =>
  (getJwt: () => string, refreshJwt: RefreshJwt) =>
  (payload?: Payload) =>
    create<DataReturned, any, any>(
      {
        ...config,
        headers: config.headers,
        ...payload,
      },
      {
        ...options,
        requestInterceptors: [
          [addAuthHeaderInterceptor, getJwt],
          [
            pathTemplatingInterceptor,
            (payload && (payload as RequestPayload).templateValues) || {},
          ],
        ],
        refreshJwt,
      }
    );

/**
 * Create simple axios request
 * @param config
 * @param options
 * @returns
 */
const createWithoutAuthLogic =
  <DataReturned, Payload extends RequestPayload | void = void>(
    config: AxiosRequestConfig,
    options?: CreateRequestOptions<DataReturned>
  ) =>
  (payload?: Payload) =>
    create<DataReturned, any, any>(
      {
        ...config,
        headers: config.headers,
        ...payload,
      },
      {
        ...options,
        requestInterceptors: [[pathTemplatingInterceptor, payload?.templateValues]],
      }
    );

/**
 * it is forbidden to use the getToken selector from the passport feature.
 * Indeed, the axios logic must not be coupled to the redux feature logic
 * @param state
 */
const getJwt = <State extends DefaultState>(state: State) => {
  const { token } = state.authentication;

  if (!token) {
    throw Error('You try to make an authenticated request but jwt is unavailable');
  }

  return token;
};

/**
 * Execute an Axios request created with createWithAuthLogic
 * adds to the request the arguments useful to the management of the passport logic
 * @param api
 * @param request
 * @param requestPayload
 * @returns
 */
const executeWithAuthLogic = <
  /** An entity is shared between applications. We don't know the typing of its state. We only know that it has at
   *  least passport entity  */
  Api extends DefaultGetThunkApi<any, any, any>,
  Payload = void,
  /** Data Returned after request fulfilled */
  DataReturned = unknown
>(
  api: Api,
  /** Axios request to make with auth logic decoration */
  request: (
    /* To get the last redux state, cannot pass directly token but a function to get token */
    getJwt: () => string,
    refreshJwt: RefreshJwt
  ) => (requestPayload: Payload) => Promise<AxiosResponse<DataReturned>>,
  requestPayload?: Payload
) =>
  request(() => getJwt(api.getState()), {
    logic: async failedRequest => {
      if (!api.extra.refreshJwtLogic) {
        return;
      }
      // Get another Token and update redux store
      const response = await api
        .dispatch(api.extra.refreshJwtLogic())
        .then(unwrapResult);

      // Reedit request header to relaunch it
      /* eslint-disable-next-line functional/immutable-data */
      failedRequest.config.headers!.Authorization = `Bearer: ${response.token}`;
    },
  })(requestPayload as Payload);

export const request = {
  create: {
    withoutAuthLogic: createWithoutAuthLogic,
    withAuthLogic: createWithAuthLogic,
  },
  executeWithAuthLogic,
};
