import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import type { Evaluations } from '@features/evaluations/services';
import type { Test } from '@features/tests/services/model';
import type { UnionTypeFromArray } from '@helpers/models/unionTypeFromArray';
import { request, RequestPayload } from '@helpers/requests';

import type { Passation } from '../passations/services';

export const sexes = ['male', 'female', 'unknown'] as const;

export type Sexe = UnionTypeFromArray<typeof sexes>;

export interface Student {
  id: string;
  name: string;
  firstname: string;
  birthdate: string;
  sexe: Sexe;
  passations?: Passation[];
  evaluations?: Evaluations[];
  tests?: Test[];
}

export type CreateStudentReturned = Student;

export interface CreateStudentPayload extends RequestPayload {
  data: Omit<Student, 'id' | 'passations' | 'evaluations' | 'tests'> & {
    passations?: EntityId[];
  };
}

const createStudentConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students',
  method: 'POST',
};

export const createStudentRequest = request.create.withAuthLogic<
  CreateStudentReturned,
  CreateStudentPayload
>(createStudentConfig);

export type EditStudentReturned = Student;

export interface EditStudentPayload extends RequestPayload {
  data: Omit<Student, 'id' | 'passations' | 'evaluations' | 'tests'> & {
    passations?: EntityId[];
  };
  templateValues: {
    id: EntityId;
  };
}

const editStudentConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students/{id}',
  method: 'PUT',
};

export const editStudentRequest = request.create.withAuthLogic<
  EditStudentReturned,
  EditStudentPayload
>(editStudentConfig);

const getStudentsCountConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students/count',
  method: 'GET',
};

export type GetStudentsCountReturned = number;

export const getStudentsCountRequest = request.create.withAuthLogic<
  GetStudentsCountReturned,
  void
>(getStudentsCountConfig);

const getStudentsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students?{filters}',
  method: 'GET',
};

export interface GetStudentsPayload {
  params: {
    _limit: number;
    _start?: number;
    _sort?: string;
    name_eq?: string;
    firstname_eq?: string;
    firstname_contains?: string;
    sexe_eq?: Sexe;
    birthdate_eq?: string;
  };
  templateValues?: {
    filters?: string;
  };
}

export type GetStudentsReturned = Student[];

export const getStudentsRequest = request.create.withAuthLogic<
  GetStudentsReturned,
  GetStudentsPayload
>(getStudentsConfig);

const getStudentConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students/{id}',
  method: 'GET',
};

export interface GetStudentPayload {
  templateValues: {
    id: EntityId;
  };
}

export type GetStudentReturned = Student;

export const getStudentRequest = request.create.withAuthLogic<
  GetStudentReturned,
  GetStudentPayload
>(getStudentConfig);

const deleteStudentConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/students/{id}',
  method: 'DELETE',
};

export interface DeleteStudentPayload {
  templateValues: { id: EntityId };
}

export type DeleteStudentReturned = Student;

export const deleteStudentRequest = request.create.withAuthLogic<
  DeleteStudentReturned,
  DeleteStudentPayload
>(deleteStudentConfig);
