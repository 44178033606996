import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import compact from 'lodash.compact';
import flatten from 'lodash.flatten';

import { logoutThunk } from '../../authentication/redux/thunks';
import { getStudentsInPassationThunk } from '../../passations/redux/thunks/getStudentsInPassation.thunk';
import { HigherLevelsEvaluationItemsValues } from '../raw-notation-form/higherLevels.items';
import { LowerLevelsEvaluationItemsValues } from '../raw-notation-form/lowerLevels.items';
import type { Evaluations } from '../services';
import {
  createEvaluationsThunk,
  editEvaluationThunk,
  getRawNotationsInSameLevelForAPassationThunk,
} from './thunks';

export type RawNotationsInSameLevel = Record<
  HigherLevelsEvaluationItemsValues | LowerLevelsEvaluationItemsValues,
  Array<number>
> & { passationToNormalize: EntityId };

interface EvaluationsState {
  collection: EntityState<Evaluations>;
  rawNotationsInSameLevel: EntityState<RawNotationsInSameLevel>;
}

export const evaluationsEntityAdapter = createEntityAdapter<Evaluations>();

export const rawNotationsInSameLevelEntityAdapter =
  createEntityAdapter<RawNotationsInSameLevel>({
    selectId: ({ passationToNormalize }) => passationToNormalize,
  });

const initialState: EvaluationsState = {
  collection: evaluationsEntityAdapter.getInitialState(),
  rawNotationsInSameLevel: rawNotationsInSameLevelEntityAdapter.getInitialState(),
};

export const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createEvaluationsThunk.fulfilled, (state, action) => {
      state.collection = evaluationsEntityAdapter.addOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(editEvaluationThunk.fulfilled, (state, action) => {
      state.collection = evaluationsEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(getStudentsInPassationThunk.fulfilled, (state, action) => {
      state.collection = evaluationsEntityAdapter.upsertMany(
        state.collection,
        compact(
          flatten(
            action.payload.map(({ tests }) =>
              tests?.map(({ evaluation }) => evaluation)
            )
          )
        )
      );
    });

    builder.addCase(
      getRawNotationsInSameLevelForAPassationThunk.fulfilled,
      (state, action) => {
        state.rawNotationsInSameLevel =
          rawNotationsInSameLevelEntityAdapter.upsertOne(
            state.rawNotationsInSameLevel,
            action.payload
          );
      }
    );

    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
