import type { AxiosRequestConfig } from 'axios';

import { request } from '@helpers/requests';

const getTestsCountConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/tests/count',
  method: 'GET',
};

export type GetTestsCountReturned = number;

export const getTestsCountRequest = request.create.withAuthLogic<
  GetTestsCountReturned,
  void
>(getTestsCountConfig);
