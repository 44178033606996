import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  DeleteCommentTemplatesPayload,
  deleteCommentTemplatesRequest,
  DeleteCommentTemplatesReturned,
} from '../../services';

type DeleteCommentTemplatesThunkPayload = DeleteCommentTemplatesPayload;
type DeleteCommentTemplatesThunkReturned = DeleteCommentTemplatesReturned;

export const deleteCommentTemplatesThunk = createAppAsyncThunk<
  DeleteCommentTemplatesThunkReturned,
  DeleteCommentTemplatesThunkPayload
>('comment-templates/delete', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    deleteCommentTemplatesRequest,
    payload
  );

  return response.data;
});
