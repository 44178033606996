import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { appBarSlice } from '@features/app-bar/redux/slice';
import { authenticationSlice } from '@features/authentication/redux/slice';
import { commentTemplatesSlice } from '@features/comment-templates/redux/slice';
import { evaluationsSlice } from '@features/evaluations/redux/slice';
import { globalProgressFeedbackSlice } from '@features/global-progress-feedback';
import { passationsSlice } from '@features/passations/redux/slice';
import { schoolSlice } from '@features/schools/redux/slice';
import { studentsSlice } from '@features/students/redux/slice';
import { testsSlice } from '@features/tests/redux/slice';
import { userPreferencesSlice } from '@features/userPreferences/slice';
import { reducerWithLocalStoragePersistance } from '@helpers/redux/reducerWithLocalStoragePersistance';

const combinedReducers = combineReducers({
  [globalProgressFeedbackSlice.name]: globalProgressFeedbackSlice.reducer,
  [userPreferencesSlice.name]: reducerWithLocalStoragePersistance(
    userPreferencesSlice.reducer,
    'userPreferences'
  ),
  [authenticationSlice.name]: reducerWithLocalStoragePersistance(
    authenticationSlice.reducer,
    'authentication'
  ),
  [appBarSlice.name]: appBarSlice.reducer,
  [schoolSlice.name]: schoolSlice.reducer,
  [studentsSlice.name]: studentsSlice.reducer,
  [passationsSlice.name]: passationsSlice.reducer,
  [evaluationsSlice.name]: evaluationsSlice.reducer,
  [testsSlice.name]: testsSlice.reducer,
  [commentTemplatesSlice.name]: commentTemplatesSlice.reducer,
} as const);

export const createStore = () =>
  configureStore({
    reducer: combinedReducers,
    middleware: getDefaultMiddleware => [...getDefaultMiddleware()] as const,
  });

export type AppStore = ReturnType<typeof createStore>;
