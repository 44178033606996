import type { EntityId } from '@reduxjs/toolkit';

import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../store/createAppAsyncThunk';
import {
  getTestsCountRequest,
  GetTestsCountReturned,
} from '../services/count.service';
import {
  CreateTestPayload,
  createTestRequest,
  CreateTestReturned,
} from '../services/create.service';
import {
  DeleteTestPayload,
  deleteTestRequest,
  DeleteTestReturned,
} from '../services/delete.service';
import {
  EditedTestReturned,
  EditTestPayload,
  editTestRequest,
} from '../services/edit.service';
import {
  GetTestsPayload,
  getTestsRequest,
  GetTestsReturned,
} from '../services/get.service';

type CreateTestsThunkPayload = CreateTestPayload;

type CreateTestsThunkReturned = CreateTestReturned;

export const createTestsThunk = createAppAsyncThunk<
  CreateTestsThunkReturned,
  Omit<CreateTestsThunkPayload, 'data'> & {
    data: {
      passation: EntityId;
      student: EntityId;
      documents: File[];
      protocoleNumber: number;
    };
  }
>('tests/create', async (payload, api) => {
  const { passation, student, documents, protocoleNumber } = payload.data;

  const formData = new FormData();

  documents.forEach(document => {
    formData.append(`files.documents`, document, document.name);
  });

  formData.append('data', JSON.stringify({ passation, student, protocoleNumber }));

  const response = await request.executeWithAuthLogic(api, createTestRequest, {
    ...payload,
    data: formData,
  });

  return response.data;
});

type EditTestThunkPayload = EditTestPayload;

type EditTestThunkReturned = EditedTestReturned;

export const editTestThunk = createAppAsyncThunk<
  EditTestThunkReturned,
  EditTestThunkPayload
>('tests/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(api, editTestRequest, payload);

  return response.data;
});

export type GetTestsThunkReturned = GetTestsReturned;

export type GetTestsThunkPayload = GetTestsPayload;

export const getTestsThunk = createAppAsyncThunk<
  GetTestsThunkReturned,
  GetTestsThunkPayload
>('tests/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(api, getTestsRequest, payload);

  return response.data;
});

export type GetCountTestsThunk = GetTestsCountReturned;

export const getCountTestsThunk = createAppAsyncThunk<GetTestsCountReturned>(
  'tests/count',
  async (payload, api) => {
    const response = await request.executeWithAuthLogic(api, getTestsCountRequest);

    return response.data;
  }
);

type DeleteTestThunkPayload = DeleteTestPayload;
type DeleteTestThunkReturned = DeleteTestReturned;

export const deleteTestThunk = createAppAsyncThunk<
  DeleteTestThunkReturned,
  DeleteTestThunkPayload
>('test/delete', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    deleteTestRequest,
    payload
  );

  return response.data;
});
