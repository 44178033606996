import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import type { Student } from '@features/students/services';
import type { UnionTypeFromArray } from '@helpers/models/unionTypeFromArray';
import { request, RequestPayload } from '@helpers/requests';

import type { School } from '../schools/services';

export const passationsLevels = [
  'petiteSection',
  'moyenneSection',
  'grandeSection',
  'cp',
  'ce1',
  'ce2',
  'cm1',
  'cm2',
  'sixieme',
  'cinquieme',
  'quatrieme',
  'troisieme',
  'seconde',
  'premiere',
  'terminale',
] as const;

export type PassationsLevels = UnionTypeFromArray<typeof passationsLevels>;

export interface Passation {
  id: number;
  date: string;
  school: School | null;
  level: PassationsLevels;
  name: string;
  students?: Student[];
}

export type StudentInPassation = Omit<Student, 'evaluations' | 'passations'> & {
  passation: string;
};

export type CreatePassationReturned = Passation;

export interface CreatePassationPayload extends RequestPayload {
  data: Omit<Passation, 'id' | 'school'> & {
    school: EntityId;
  };
}

const createPassationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations',
  method: 'POST',
};

export const createPassationRequest = request.create.withAuthLogic<
  CreatePassationReturned,
  CreatePassationPayload
>(createPassationConfig);

export type EditPassationReturned = Passation;

export interface EditPassationPayload extends RequestPayload {
  data: Partial<
    Omit<Passation, 'id' | 'school' | 'students'> & {
      school: EntityId;
      students: EntityId[];
    }
  >;
  templateValues: {
    id: EntityId;
  };
}

const editPassationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations/{id}',
  method: 'PUT',
};

export const editPassationsRequest = request.create.withAuthLogic<
  EditPassationReturned,
  EditPassationPayload
>(editPassationConfig);

const getPassationsCountConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations/count',
  method: 'GET',
};

export type GetPassationsCountReturned = number;

export const getPassationsCountRequest = request.create.withAuthLogic<
  GetPassationsCountReturned,
  void
>(getPassationsCountConfig);

const getPassationsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations?{filters}',
  method: 'GET',
};

export interface GetPassationsPayload extends RequestPayload {
  params: {
    _limit: number;
    _sort?: string;
    _start?: number;
    school_eq?: EntityId;
    level_eq?: PassationsLevels;
    name_eq?: string;
    date_eq?: string;
  };
  templateValues?: {
    filters?: string;
  };
}

export type GetPassationsReturned = Passation[];

export const getPassationsRequest = request.create.withAuthLogic<
  GetPassationsReturned,
  GetPassationsPayload
>(getPassationsConfig);

const getPassationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations/{id}',
  method: 'GET',
};

export interface GetPassationPayload extends RequestPayload {
  templateValues?: {
    id: string;
  };
}

export type GetPassationReturned = Passation;

export const getPassationRequest = request.create.withAuthLogic<
  GetPassationReturned,
  GetPassationPayload
>(getPassationConfig);

const deletePassationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations/{id}',
  method: 'DELETE',
};

export interface DeletePassationPayload {
  templateValues: { id: EntityId };
}

export type DeletePassationReturned = Passation;

export const deletePassationRequest = request.create.withAuthLogic<
  DeletePassationReturned,
  DeletePassationPayload
>(deletePassationConfig);

const getStudentsInPassationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/passations/details/{id}',
  method: 'GET',
};

export interface GetStudentsInPassationPayload extends RequestPayload {
  templateValues?: {
    id: string;
  };
}

export type GetStudentsInPassationReturned = StudentInPassation[];

export const getStudentsInPassationRequest = request.create.withAuthLogic<
  GetStudentsInPassationReturned,
  GetStudentsInPassationPayload
>(getStudentsInPassationConfig);
