import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { DisplayGlobalProgressFeedback } from '@features/global-progress-feedback';

import { appInit } from './appInit';

const AppWithStore = React.lazy(() => import('./AppWithStore'));

const { store } = appInit();

const root = createRoot(document.getElementById('root')!);

root.render(
  <ReduxProvider store={store}>
    <Suspense fallback={<DisplayGlobalProgressFeedback />}>
      <AppWithStore />
    </Suspense>
  </ReduxProvider>
);
