import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { logoutThunk } from '@features/authentication/redux/thunks';
import { editSchoolThunk } from '@features/schools/redux/thunks/editSchool.thunk';
import type { School } from '@features/schools/services';

import { createSchoolThunk } from './thunks/createSchool.thunk';
import { deleteSchoolThunk } from './thunks/deleteSchool.thunk';
import { getCountSchoolsThunk } from './thunks/getCountSchools.thunk';
import { getSchoolsThunk } from './thunks/getSchools.thunk';

interface SchoolState {
  collection: EntityState<School>;
  count: number;
}

export const schoolEntityAdapter = createEntityAdapter<School>();

const initialState: SchoolState = {
  collection: schoolEntityAdapter.getInitialState(),
  count: 0,
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSchoolsThunk.fulfilled, (state, action) => {
      state.collection = schoolEntityAdapter.setAll(
        state.collection,
        action.payload
      );
    });

    builder.addCase(createSchoolThunk.fulfilled, (state, action) => {
      state.collection = schoolEntityAdapter.addOne(
        state.collection,
        action.payload
      );
      state.count += 1;
    });

    builder.addCase(editSchoolThunk.fulfilled, (state, action) => {
      state.collection = schoolEntityAdapter.upsertOne(
        state.collection,
        action.payload
      );
    });

    builder.addCase(deleteSchoolThunk.fulfilled, (state, action) => {
      state.collection = schoolEntityAdapter.removeOne(
        state.collection,
        action.payload.id
      );
      state.count -= 1;
    });

    builder.addCase(getCountSchoolsThunk.fulfilled, (state, action) => {
      state.count = action.payload;
    });

    builder.addCase(logoutThunk.fulfilled, () => initialState);
  },
});
