import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  EditSchoolPayload,
  editSchoolRequest,
  EditSchoolReturned,
} from '../../services';

type EditSchoolThunkPayload = EditSchoolPayload;
type EditSchoolThunkReturned = EditSchoolReturned;

export const editSchoolThunk = createAppAsyncThunk<
  EditSchoolThunkReturned,
  EditSchoolThunkPayload
>('school/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    editSchoolRequest,
    payload
  );

  return response.data;
});
