import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  DeleteStudentPayload,
  deleteStudentRequest,
  DeleteStudentReturned,
} from '../../services';

type DeleteStudentThunkPayload = DeleteStudentPayload;
type DeleteStudentThunkReturned = DeleteStudentReturned;

export const deleteStudentThunk = createAppAsyncThunk<
  DeleteStudentThunkReturned,
  DeleteStudentThunkPayload
>('student/delete', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    deleteStudentRequest,
    payload
  );

  return response.data;
});
