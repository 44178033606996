import type { Theme } from '@ui-kit';

import { createAppAsyncThunk } from '../../store/createAppAsyncThunk';

// use thunk to change theme for future implementation of server side persistence ; for now it's a local storage
// persistence
export const overrideThemeOptionsEntityThunk = createAppAsyncThunk<Theme, Theme>(
  'user-preferences/themes/change',
  payload => payload
);

export const setIsPreferredDarkThemeEntityThunk = createAppAsyncThunk<
  boolean,
  boolean
>('user-preferences/themes/set-is-preferred-dark-theme', payload => payload);
