import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import type { Passation } from '@features/passations/services';
import { request, RequestPayload } from '@helpers/requests';

export type SchoolsType = { id: number; label: string };

export interface School {
  id: number;
  name: string;
  school_types: SchoolsType[];
  city?: string;
  address?: string;
  phone?: string;
  passations?: Passation[];
  zipCode?: string;
}

export type CreateSchoolReturned = School;

export interface CreateSchoolPayload extends RequestPayload {
  data: Omit<School, 'id' | 'school_types'> & {
    school_types: EntityId[];
  };
}

const createSchoolConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools',
  method: 'POST',
};

export const createSchoolRequest = request.create.withAuthLogic<
  CreateSchoolReturned,
  CreateSchoolPayload
>(createSchoolConfig);

export type EditSchoolReturned = School;

export interface EditSchoolPayload extends RequestPayload {
  data: Omit<School, 'id' | 'school_types'> & {
    school_types: EntityId[];
  };
  templateValues: {
    id: EntityId;
  };
}

const editSchoolConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools/{id}',
  method: 'PUT',
};

export const editSchoolRequest = request.create.withAuthLogic<
  EditSchoolReturned,
  EditSchoolPayload
>(editSchoolConfig);

const getSchoolConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools/{id}',
  method: 'GET',
};

export interface GetSchoolPayload {
  templateValues: { id: EntityId };
}

export type GetSchoolReturned = School;

export const getSchoolRequest = request.create.withAuthLogic<
  GetSchoolReturned,
  GetSchoolPayload
>(getSchoolConfig);

const getSchoolsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools',
  method: 'GET',
};

export interface GetSchoolsPayload {
  params: {
    _limit: number;
    _start?: number;
    _sort?: string;
    name_contains?: string;
    name_eq?: string;
  };
}

export type GetSchoolsReturned = School[];

export const getSchoolsRequest = request.create.withAuthLogic<
  GetSchoolsReturned,
  GetSchoolsPayload
>(getSchoolsConfig);

const deleteSchoolConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools/{id}',
  method: 'DELETE',
};

export interface DeleteSchoolPayload {
  templateValues: { id: EntityId };
}

export type DeleteSchoolReturned = School;

export const deleteSchoolRequest = request.create.withAuthLogic<
  DeleteSchoolReturned,
  DeleteSchoolPayload
>(deleteSchoolConfig);

const countSchoolConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools',
  method: 'POST',
};

export const countSchoolRequest = request.create.withAuthLogic<
  CreateSchoolReturned,
  CreateSchoolPayload
>(countSchoolConfig);

const getSchoolCountConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/schools/count',
  method: 'GET',
};

export type GetSchoolsCountReturned = number;

export const getSchoolsCountRequest = request.create.withAuthLogic<
  GetSchoolsCountReturned,
  void
>(getSchoolCountConfig);

const getSchoolsTypesConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/school-types',
  method: 'GET',
};

export interface GetSchoolsTypesPayload {
  params: {
    _limit?: number;
    _start?: number;
  };
}

export type GetSchoolsTypesReturned = SchoolsType[];

export const getSchoolsTypesRequest = request.create.withAuthLogic<
  GetSchoolsTypesReturned,
  GetSchoolsTypesPayload
>(getSchoolsTypesConfig);
