import { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import { request, RequestPayload } from '@helpers/requests';

import type { Evaluations } from '../../evaluations/services';
import type { Test } from './model';

export type EditedTestReturned = Test;

export interface EditTestPayload extends RequestPayload {
  data: Partial<{
    documents: string;
    evaluation: Partial<Evaluations>;
    student: string;
    passation: string;
    comment: string;
    protocoleNumber: number;
  }>;
  templateValues: {
    id: EntityId;
  };
}

const editTestConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/tests/{id}',
  method: 'PUT',
};

export const editTestRequest = request.create.withAuthLogic<
  EditedTestReturned,
  EditTestPayload
>(editTestConfig);
