import type { AxiosRequestConfig } from 'axios';

import { request } from '@helpers/requests';

import type { Test } from './model';

const getTestsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/tests?{filters}',
  method: 'GET',
};

export interface GetTestsPayload {
  params: {
    _limit: number;
    _start: number;
  };
  templateValues?: {
    filters?: string;
  };
}

export type GetTestsReturned = Test[];

export const getTestsRequest = request.create.withAuthLogic<
  GetTestsReturned,
  GetTestsPayload
>(getTestsConfig);
