import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  EditStudentPayload,
  editStudentRequest,
  EditStudentReturned,
} from '../../services';

type EditStudentThunkPayload = EditStudentPayload;
type EditStudentThunkReturned = EditStudentReturned;

export const editStudentThunk = createAppAsyncThunk<
  EditStudentThunkReturned,
  EditStudentThunkPayload
>('student/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    editStudentRequest,
    payload
  );

  return response.data;
});
