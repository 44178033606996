import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  CreateSchoolPayload,
  createSchoolRequest,
  CreateSchoolReturned,
} from '../../services';

type CreateSchoolThunkPayload = CreateSchoolPayload;
type CreateSchoolThunkReturned = CreateSchoolReturned;

export const createSchoolThunk = createAppAsyncThunk<
  CreateSchoolThunkReturned,
  CreateSchoolThunkPayload
>('school/create', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    createSchoolRequest,
    payload
  );

  return response.data;
});
