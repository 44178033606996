import { get as lsGet, set as lsSet } from 'local-storage';

import type { StorageKeys } from './keys';

type Data = string | unknown[] | Record<string, unknown> | null;

const get = <DataToReturn extends Data>(key: StorageKeys) =>
  lsGet<DataToReturn | null>(key);

const set = <DataToSet extends Data>(key: StorageKeys, value: DataToSet) =>
  lsSet(key, value);

/**
 * local storage api overlay.
 * Allows to control the registered keys,
 * clean them and type the retrieved data
 */
export const localStorageManager = { get, set };
