import { createSlice } from '@reduxjs/toolkit';

import type { Theme } from '@ui-kit';

import {
  overrideThemeOptionsEntityThunk,
  setIsPreferredDarkThemeEntityThunk,
} from './thunks';

export type UserPreferencesState = {
  isPreferredDarkTheme: boolean;
  overrideThemeOptions: Theme | null;
};

// The default theme is customized according to user preference. But it can also be overridden
const initialState: UserPreferencesState = {
  isPreferredDarkTheme: true,
  overrideThemeOptions: null,
};

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(overrideThemeOptionsEntityThunk.fulfilled, (state, action) => {
      // @ts-ignore
      state.overrideThemeOptions = action.payload;
    });

    builder.addCase(
      setIsPreferredDarkThemeEntityThunk.fulfilled,
      (state, action) => {
        state.isPreferredDarkTheme = action.payload;
      }
    );
  },
});
