import { createSlice } from '@reduxjs/toolkit';

import type { StrapiUser } from '@helpers/models/strapi';

import {
  authenticationByCredentialsThunk,
  getMeThunk,
  logoutThunk,
  resetPasswordThunk,
} from './thunks';

interface AuthenticationState {
  token: null | string;
  user: null | StrapiUser;
  identifierMemorized: null | string;
}

const initialState: AuthenticationState = {
  token: null,
  user: null,
  identifierMemorized: null,
};

export type AuthenticationEntityState = typeof initialState;

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(authenticationByCredentialsThunk.fulfilled, (state, action) => {
      state.token = action.payload.data.jwt;
      state.user = action.payload.data.user;
      state.identifierMemorized = action.payload.shouldMemorizeIdentifier
        ? action.payload.data.user.email
        : null;
    });

    builder.addCase(getMeThunk.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    // On disconnect we want to keep username Memorized in LS after the clear done in logout thunk
    builder.addCase(logoutThunk.fulfilled, state => ({
      ...initialState,
      identifierMemorized: state.identifierMemorized,
    }));

    builder.addCase(resetPasswordThunk.fulfilled, (state, action) => {
      state.token = action.payload.jwt;
      state.user = action.payload.user;
    });
  },
});
