import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetStudentsInPassationPayload,
  getStudentsInPassationRequest,
  GetStudentsInPassationReturned,
} from '../../services';

export type GetPassationDetailsThunkReturned = GetStudentsInPassationReturned;
export type GetPassationDetailsThunkPayload = GetStudentsInPassationPayload;

export const getStudentsInPassationThunk = createAppAsyncThunk<
  GetPassationDetailsThunkReturned,
  GetPassationDetailsThunkPayload
>('passation/details/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getStudentsInPassationRequest,
    payload
  );

  return response.data;
});
