import type { AnyRequestInterceptor } from '@helpers/axios-interceptors/models';

/**
 * Request Axios interceptor
 * add Jwt token in header request
 * @param config
 * @param getToken
 */
export const addAuthHeaderInterceptor: AnyRequestInterceptor<() => string> = (
  config,
  getToken
) => ({
  ...config,
  headers: { ...config.headers, Authorization: `Bearer ${getToken()}` },
});
