import type { EntityId } from '@reduxjs/toolkit';
import type { AxiosRequestConfig } from 'axios';

import { request, RequestPayload } from '@helpers/requests';

import type { Test } from '../tests/services/model';
import type { HigherLevelsEvaluationItemsValues } from './raw-notation-form/higherLevels.items';
import type { LowerLevelsEvaluationItemsValues } from './raw-notation-form/lowerLevels.items';

export type BlockageReasons = 'instructionNotRespected' | 'exerciseNotPerformed';

export type Evaluations = {
  id: string;
  test?: Test;
  items?: Record<
    HigherLevelsEvaluationItemsValues | LowerLevelsEvaluationItemsValues,
    number | undefined
  >;
  comment?: string;
  hasSuccess?: boolean;
  hasShortcomings?: boolean;
  hasBlockages?: boolean;
  observation?: string;
  blockages?: Partial<
    Record<
      HigherLevelsEvaluationItemsValues | LowerLevelsEvaluationItemsValues,
      BlockageReasons
    >
  > | null;
};

export type CreateEvaluationsReturned = Evaluations;

export interface CreateEvaluationsPayload extends RequestPayload {
  data: Omit<Evaluations, 'id' | 'test'> & { test: string };
}

const createEvaluationsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/evaluations',
  method: 'POST',
};

export const createEvaluationsRequest = request.create.withAuthLogic<
  CreateEvaluationsReturned,
  CreateEvaluationsPayload
>(createEvaluationsConfig);

export type EditEvaluationReturned = Evaluations;

export interface EditEvaluationPayload extends RequestPayload {
  data: Partial<Evaluations>;
  templateValues: {
    id: EntityId;
  };
}

const editEvaluationConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/evaluations/{id}',
  method: 'PUT',
};

export const editEvaluationRequest = request.create.withAuthLogic<
  EditEvaluationReturned,
  EditEvaluationPayload
>(editEvaluationConfig);

const getEvaluationsCountConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/evaluations/count',
  method: 'GET',
};

export type GetEvaluationsCountReturned = number;

export const getEvaluationsCountRequest = request.create.withAuthLogic<
  GetEvaluationsCountReturned,
  void
>(getEvaluationsCountConfig);

const getEvaluationsConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/evaluations?{filters}',
  method: 'GET',
};

export interface GetEvaluationsPayload {
  params?: {
    _limit?: number;
    _start?: number;
    _sort?: string;
  };
  templateValues?: {
    filters?: string;
  };
}

export type GetEvaluationsReturned = Evaluations[];

export const getEvaluationsRequest = request.create.withAuthLogic<
  GetEvaluationsReturned,
  GetEvaluationsPayload
>(getEvaluationsConfig);

const getEvaluationByIdConfig: AxiosRequestConfig = {
  headers: { 'Content-type': 'application/json' },
  baseURL: process.env.SERVER_BASE_URL,
  url: '/evaluations/{id}',
  method: 'GET',
};

export interface GetEvaluationByIdPayload {
  templateValues: {
    id: EntityId;
  };
}

export type GetEvaluationByIdReturned = Evaluations;

export const getEvaluationByIdRequest = request.create.withAuthLogic<
  GetEvaluationByIdReturned,
  GetEvaluationByIdPayload
>(getEvaluationByIdConfig);
