import {
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  createAsyncThunk as reduxCreateAsyncThunk,
} from '@reduxjs/toolkit';

import type { AppAsyncThunkConfig } from './models';

/**
 * Create Async Thunk
 * is used to create a thunk in redux toolkit
 * this abstraction allows you to benefit
 * store application typing
 * @param typePrefix
 * @param payloadCreator
 * @param options
 */
export const createAppAsyncThunk = <ThunkReturned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    ThunkReturned,
    ThunkArg,
    AppAsyncThunkConfig
  >,
  options?: Parameters<typeof reduxCreateAsyncThunk>[2]
) => createAsyncThunk(typePrefix, payloadCreator, options);
