import { subYears } from 'date-fns';
import compact from 'lodash.compact';
import qs from 'qs';

import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../store/createAppAsyncThunk';
import type { Passation } from '../../passations/services';
import type {
  CreateEvaluationsPayload,
  CreateEvaluationsReturned,
  EditEvaluationPayload,
  EditEvaluationReturned,
  GetEvaluationByIdPayload,
  GetEvaluationByIdReturned,
  GetEvaluationsPayload,
  GetEvaluationsReturned,
} from '../services';
import {
  createEvaluationsRequest,
  editEvaluationRequest,
  getEvaluationByIdRequest,
  getEvaluationsRequest,
} from '../services';
import type { RawNotationsInSameLevel } from './slice';

type CreateEvaluationsThunkPayload = CreateEvaluationsPayload;

type CreateEvaluationsThunkReturned = CreateEvaluationsReturned;

export const createEvaluationsThunk = createAppAsyncThunk<
  CreateEvaluationsThunkReturned,
  CreateEvaluationsThunkPayload
>('evaluations/create', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    createEvaluationsRequest,
    payload
  );

  return response.data;
});

type EditEvaluationThunkPayload = EditEvaluationPayload;

type EditEvaluationThunkReturned = EditEvaluationReturned;

export const editEvaluationThunk = createAppAsyncThunk<
  EditEvaluationThunkReturned,
  EditEvaluationThunkPayload
>('evaluations/edit', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    editEvaluationRequest,
    payload
  );

  return response.data;
});

export type GetEvaluationsThunkReturned = GetEvaluationsReturned;

export type GetEvaluationsThunkPayload = GetEvaluationsPayload;

export const getEvaluationsThunk = createAppAsyncThunk<
  GetEvaluationsThunkReturned,
  GetEvaluationsThunkPayload
>('evaluations/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getEvaluationsRequest,
    payload
  );

  return response.data;
});

export type GetEvaluationByIdThunkReturned = GetEvaluationByIdReturned;

export type GetEvaluationByIdThunkPayload = GetEvaluationByIdPayload;

export const getEvaluationByIdThunk = createAppAsyncThunk<
  GetEvaluationByIdThunkReturned,
  GetEvaluationByIdThunkPayload
>('evaluations/get-by-id', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getEvaluationByIdRequest,
    payload
  );

  return response.data;
});

export type GetRawNotationsInSameLevelForAPassationThunkReturned =
  RawNotationsInSameLevel;

export type GetRawNotationsInSameLevelForAPassationThunkPayload = {
  passationToNormalize: Passation;
};

export const getRawNotationsInSameLevelForAPassationThunk = createAppAsyncThunk<
  GetRawNotationsInSameLevelForAPassationThunkReturned,
  GetRawNotationsInSameLevelForAPassationThunkPayload
>('evaluations/normalization/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(api, getEvaluationsRequest, {
    params: {
      _limit: 1500,
      _sort: 'id:DESC',
    },
    templateValues: {
      filters: qs.stringify({
        _where: [
          {
            // get passation from same level
            'test.passation.level_eq': payload.passationToNormalize.level,
            // exclude this one
            id_ne: payload.passationToNormalize.id,
            // create at least after three years ago
            created_at_gte: subYears(new Date(), 3),
          },
        ],
      }),
    },
  });

  return response.data
    .map(evaluation => evaluation.items)
    .reduce<RawNotationsInSameLevel>(
      (previous, current) => ({
        passationToNormalize: payload.passationToNormalize.id,
        auditoryMemory: compact([
          ...previous.auditoryMemory,
          current?.auditoryMemory,
        ]),
        immediateMemory: compact([
          ...previous.immediateMemory,
          current?.immediateMemory,
        ]),
        learningAfterRepetitions: compact([
          ...previous.learningAfterRepetitions,
          current?.learningAfterRepetitions,
        ]),
        mentalCalculation: compact([
          ...previous.mentalCalculation,
          current?.mentalCalculation,
        ]),
        mentalRegularity: compact([
          ...previous.mentalRegularity,
          current?.mentalRegularity,
        ]),
        mentalVelocityAndQuantity: compact([
          ...previous.mentalVelocityAndQuantity,
          current?.mentalVelocityAndQuantity,
        ]),
        richnessAndPrecisionVocabulary: compact([
          ...previous.richnessAndPrecisionVocabulary,
          current?.richnessAndPrecisionVocabulary,
        ]),
        shortProblem: compact([...previous.shortProblem, current?.shortProblem]),
        understandingInstructions: compact([
          ...previous.understandingInstructions,
          current?.understandingInstructions,
        ]),
        verbalCategorization: compact([
          ...previous.verbalCategorization,
          current?.verbalCategorization,
        ]),
        volumeInSpace: compact([...previous.volumeInSpace, current?.volumeInSpace]),
        inductiveAndDeductiveLogic: compact([
          ...previous.inductiveAndDeductiveLogic,
          current?.inductiveAndDeductiveLogic,
        ]),
        quantitativeLogicReasoning: compact([
          ...previous.quantitativeLogicReasoning,
          current?.quantitativeLogicReasoning,
        ]),
        organizationSkill: compact([
          ...previous.organizationSkill,
          current?.organizationSkill,
        ]),
        observationalSkill: compact([
          ...previous.observationalSkill,
          current?.observationalSkill,
        ]),
        finesseUnderstanding: compact([
          ...previous.finesseUnderstanding,
          current?.finesseUnderstanding,
        ]),
        understandingAndLogicalMathematicalReasoning: compact([
          ...previous.understandingAndLogicalMathematicalReasoning,
          current?.understandingAndLogicalMathematicalReasoning,
        ]),
        languageAnalyzeAndSynthesis: compact([
          ...previous.languageAnalyzeAndSynthesis,
          current?.languageAnalyzeAndSynthesis,
        ]),
      }),
      {
        passationToNormalize: payload.passationToNormalize.id,
        auditoryMemory: [],
        immediateMemory: [],
        learningAfterRepetitions: [],
        mentalCalculation: [],
        mentalRegularity: [],
        mentalVelocityAndQuantity: [],
        richnessAndPrecisionVocabulary: [],
        shortProblem: [],
        understandingInstructions: [],
        verbalCategorization: [],
        volumeInSpace: [],
        inductiveAndDeductiveLogic: [],
        quantitativeLogicReasoning: [],
        organizationSkill: [],
        observationalSkill: [],
        finesseUnderstanding: [],
        understandingAndLogicalMathematicalReasoning: [],
        languageAnalyzeAndSynthesis: [],
      }
    );
});
