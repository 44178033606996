import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDisplay: false,
};

export type GlobalProgressFeedbackState = typeof initialState;

export const globalProgressFeedbackSlice = createSlice({
  name: 'globalProgressFeedback',
  initialState,
  reducers: {
    display: state => {
      state.isDisplay = true;
    },
    hide: state => {
      state.isDisplay = false;
    },
  },
});

export const globalProgressFeedbackSliceActions =
  globalProgressFeedbackSlice.actions;
