import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetPassationPayload,
  getPassationRequest,
  GetPassationReturned,
} from '../../services';

export type GetPassationThunkReturned = GetPassationReturned;
export type GetPassationThunkPayload = GetPassationPayload;

export const getPassationThunk = createAppAsyncThunk<
  GetPassationThunkReturned,
  GetPassationThunkPayload
>('passation/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getPassationRequest,
    payload
  );

  return response.data;
});
