import { createSlice } from '@reduxjs/toolkit';

interface AppBarState {
  isDrawerOpen: boolean;
}

const initialState: AppBarState = {
  isDrawerOpen: false,
};

export type AppBarEntityState = typeof initialState;

export const appBarSlice = createSlice({
  name: 'appBar',
  initialState,
  reducers: {
    toogleDrawer: state => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
});
