import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  GetPassationsPayload,
  getPassationsRequest,
  GetPassationsReturned,
} from '../../services';

export type GetPassationsThunkReturned = GetPassationsReturned;
export type GetPassationsThunkPayload = GetPassationsPayload;

export const getPassationsThunk = createAppAsyncThunk<
  GetPassationsThunkReturned,
  GetPassationsThunkPayload
>('passations/get', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    getPassationsRequest,
    payload
  );

  return response.data;
});
