import { request } from '@helpers/requests';

import { createAppAsyncThunk } from '../../../../store/createAppAsyncThunk';
import {
  DeleteSchoolPayload,
  deleteSchoolRequest,
  DeleteSchoolReturned,
} from '../../services';

type DeleteSchoolThunkPayload = DeleteSchoolPayload;
type DeleteSchoolThunkReturned = DeleteSchoolReturned;

export const deleteSchoolThunk = createAppAsyncThunk<
  DeleteSchoolThunkReturned,
  DeleteSchoolThunkPayload
>('school/delete', async (payload, api) => {
  const response = await request.executeWithAuthLogic(
    api,
    deleteSchoolRequest,
    payload
  );

  return response.data;
});
