import { createStore, AppStore } from './store/createStore';

type AppInit = () => {
  store: AppStore;
};

export const appInit: AppInit = () => {
  const store = createStore();

  return { store };
};
